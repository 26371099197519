
import { getSliceComponentProps } from '@prismicio/vue/components';
import ratingPushPushAdapter from '@/slices/RatingPush/adapter';

export default {
  name: 'RatingPush',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    sliceData() {
      return ratingPushPushAdapter(this.slice, {
        i18n: this.$i18n,
        enhancedLinkSerializer: this.$enhancedLinkSerializer,
      });
    },
  },
};
